// Chakra imports
import {
    Flex,
    Grid, Text, useColorModeValue
} from "@chakra-ui/react";
// Custom icons

import React from "react";
import Location from "./components/Location";


export default function Default() {
  // Chakra Color Mode
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text
        color={textColor}
        fontWeight="bold"
        fontSize="3xl"
        mb="30px"
        ps="20px"
      >
        Contact Us
      </Text>

      <Grid
        gap="24px"
        mb={{ lg: "26px" }}
      >
        <Location />
      </Grid>
    </Flex>
  );
}
