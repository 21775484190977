// Chakra imports
import {
    Button,
    Flex,
    Grid,
    Icon,
    Text,
    useColorModeValue,
  } from "@chakra-ui/react";
import tncBz236v12afImg from "../../../Products/Bullet/img/TNC-BZ236V12AF.png";
import tncB210v12afsImg from "../../../Products/Bullet/img/TNC-B210V12AFS.png";
import tncB217v42af from "../../../Products/Bullet/img/TNC-B217-V42AF.png";
import tncBz204v12afsImg from "../../../Products/Bullet/img/TNC-BZ204V12AFS.png";
import tncD210v21afImg from "../../../Products/Dome/img/TNC-D210V21AF.png";
import tncFv120fImg from "../../../Products/Dome/img/TNC-FV120F.png";
import tncV210f18Img from "../../../Products/Dome/img/TNC-V210F18.png";
import tncD210v30afsImg from "../../../Products/Dome/img/TNC-D210V30AFS.png";
import tncSpt2m36AF from "../../../Products/Ptz/img/TNC-SPT2M-36AF.png";
import tncBp516afs from "../../../Products/Ptz/img/TNC-BP516AFS.png";
import tncVs210afs from "../../../Products/Ptz/img/TNC-VS210AFS.png";
import tncGb210v12afs from "../../../Products/Special/img/TNC-GB210V12AFS.png";
import tncV210v06af from "../../../Products/Special/img/TNC-V210V06AF.png";
import tncSd236afs from "../../../Products/SpeedDome/img/TNC-SD236AFS.png";

  // Custom components
  import Card from "components/Card/Card";
  import CardBody from "components/Card/CardBody";
  import CardHeader from "components/Card/CardHeader";
  import React from "react";
  import { FaPlus } from "react-icons/fa";
  import ProjectCard from "./ProjectCard";
  
  const AllProducts = ({ title, description }) => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
  
    return (
      <Card p='16px' my='24px'>
        <CardHeader p='12px 5px' mb='12px'>
          <Flex direction='column'>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              {title}
            </Text>
            <Text fontSize='sm' color='gray.500' fontWeight='400'>
              {description}
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px='5px'>
          <Grid
            templateColumns={{ sm: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }}
            templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
            gap='24px'>
            <ProjectCard
                image={tncSd236afs}
                name={"TNC-SD236AFS"}
                category={"Speed Dome"}
                description={
                    "HD IR PTZ Camera"
                }
                productLink={"/#/product/speed-dome/tnc-sd236afs"}
            />
            <ProjectCard
                image={tncSd236afs}
                name={"TNC-SD818AFS"}
                category={"Speed Dome"}
                description={
                    "4K 8M HD IR PRZ Camera"
                }
                productLink={"/#/product/speed-dome/tnc-sd818afs"}
            />
            <ProjectCard
              image={tncBz236v12afImg}
              name={"TNC-BZ236V12AF"}
              category={"Bullet"}
              description={
                "2M Network IR Zoom Camera"
              }
              productLink={"/#/product/bullet/tnc-bz236v12af"}
            />
            <ProjectCard
              image={tncBz204v12afsImg}
              name={"TNC-BZ204V12AFS"}
              category={"Bullet"}
              description={
                "2M Network IR Camera"
              }
              productLink={"/#/product/bullet/tnc-bz204v12afs"}
            />
            <ProjectCard
              image={tncB210v12afsImg}
              name={"TNC-B210V12AFS"}
              category={"Bullet"}
              description={
                  "2M Network IR Camera"
              }
              productLink={"/#/product/bullet/tnc-b210v12afs"}
            />
              <ProjectCard
                  image={tncB210v12afsImg}
                  name={"TNC-B510V12AF"}
                  category={"Bullet"}
                  description={
                      "5M Network IR Camera"
                  }
                  productLink={"/#/product/bullet/tnc-b510v12af"}
              />
              <ProjectCard
                  image={tncB210v12afsImg}
                  name={"TNC-B120V12AFS"}
                  category={"Bullet"}
                  description={
                      "12M Network IR Camera"
                  }
                  productLink={"/#/product/bullet/tnc-b120v12afs"}
              />
              <ProjectCard
                  image={tncB217v42af}
                  name={"UTC-8060R"}
                  category={"Bullet"}
                  description={
                      "4K 8M IR Camera"
                  }
                  productLink={"/#/product/bullet/utc-8060r"}
              />

              <ProjectCard
                  image={tncFv120fImg}
                  name={"TNC-FV120F"}
                  category={"Dome"}
                  description={
                      "12M Fisheye Camera"
                  }
                  productLink={"/#/product/dome/tnc-fv120f"}
              />
              <ProjectCard
                  image={tncV210f18Img}
                  name={"TNC-V210F18"}
                  category={"Dome"}
                  description={
                      "2M IR Camera"
                  }
                  productLink={"/#/product/dome/tnc-v210f18"}
              />
              <ProjectCard
                  image={tncD210v21afImg}
                  name={"TNC-D210V21AF"}
                  category={"Dome"}
                  description={
                      "2M IR Camera"
                  }
                  productLink={"/#/product/dome/tnc-d210v21af"}
              />
              <ProjectCard
                  image={tncD210v21afImg}
                  name={"TNC-D210V21AFS"}
                  category={"Dome"}
                  description={
                      "2M IR Camera"
                  }
                  productLink={"/#/product/dome/tnc-d210v21afs"}
              />
              <ProjectCard
                  image={tncD210v30afsImg}
                  name={"TNC-D210V30AFS"}
                  category={"Dome"}
                  description={
                      "2M IR Camera"
                  }
                  productLink={"/#/product/dome/tnc-d210v30afs"}
              />
              <ProjectCard
                  image={tncD210v21afImg}
                  name={"TNC-D510V21AF"}
                  category={"Dome"}
                  description={
                      "5M IR Camera"
                  }
                  productLink={"/#/product/dome/tnc-d510v21af"}
              />
              <ProjectCard
                  image={tncD210v21afImg}
                  name={"UTC-800DR"}
                  category={"Dome"}
                  description={
                      "4K 8M IR Camera"
                  }
                  productLink={"/#/product/dome/utc-800dr"}
              />
              <ProjectCard
                  image={tncBp516afs}
                  name={"TNC-BP516AFS"}
                  category={"PTZ"}
                  description={
                      "5M IR Camera"
                  }
                  productLink={"/#/product/ptz/tnc-bp516afs"}
              />
              <ProjectCard
                  image={tncSpt2m36AF}
                  name={"TNC-BP21V36AFS"}
                  category={"PTZ"}
                  description={
                      "2M IR Camera"
                  }
                  productLink={"/#/product/ptz/tnc-bp21v36afs"}
              />
              <ProjectCard
                  image={tncSpt2m36AF}
                  name={"TNC-BP21V08AFS"}
                  category={"PTZ"}
                  description={
                      "2M IR Camera"
                  }
                  productLink={"/#/product/ptz/tnc-bp21v08afs"}
              />
              <ProjectCard
                  image={tncVs210afs}
                  name={"TNC-VS210AF"}
                  category={"PTZ"}
                  description={
                      "2M IR Camera"
                  }
                  productLink={"/#/product/ptz/tnc-vs210af"}
              />
              <ProjectCard
                  image={tncVs210afs}
                  name={"TNC-VS210AFS"}
                  category={"PTZ"}
                  description={
                      "2M IR Camera"
                  }
                  productLink={"/#/product/ptz/tnc-vs210afs"}
              />
              <ProjectCard
                  image={tncVs210afs}
                  name={"TNC-VS510AFS"}
                  category={"PTZ"}
                  description={
                      "5M IR Camera"
                  }
                  productLink={"/#/product/ptz/tnc-vs510afs"}
              />
              <ProjectCard
                  image={tncGb210v12afs}
                  name={"TNC-GB210V12AFS"}
                  category={"SPECIAL"}
                  description={
                      "2M Global Shutter Camera"
                  }
                  productLink={"/#/product/special/tnc-gb210v12afs"}
              />
              <ProjectCard
                  image={tncV210v06af}
                  name={"TNC-V210V06AF"}
                  category={"SPECIAL"}
                  description={
                      "2M Eyeball Camera"
                  }
                  productLink={"/#/product/special/tnc-v210v06af"}
              />
              <ProjectCard
                  image={tncV210v06af}
                  name={"TNC-V210V06AFS"}
                  category={"SPECIAL"}
                  description={
                      "2M Eyeball Camera"
                  }
                  productLink={"/#/product/special/tnc-v210v06afs"}
              />
              <ProjectCard
                  image={tncV210v06af}
                  name={"TNC-V510V06AFS"}
                  category={"SPECIAL"}
                  description={
                      "5M Eyeball Camera"
                  }
                  productLink={"/#/product/special/tnc-v510v06afs"}
              />
          </Grid>
        </CardBody>
      </Card>
    );
  };
  
  export default AllProducts;