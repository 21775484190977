import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  ListItem,
  Progress,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import tncSd236afsImg from "./img/TNC-SD236AFS.png";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { BsStarFill, BsStarHalf } from "react-icons/bs";

function TncSd818afs() {
  const [currentImage, setCurrentImage] = useState(tncSd236afsImg);
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card mt={{ sm: "125px", md: "75px" }}>
      <CardHeader mb="42px">
        <Text color={textColor} fontSize="lg" fontWeight="bold">
          Product Details
        </Text>
      </CardHeader>
      <CardBody>
        <Flex direction="column" w="100%">
          <Flex
            direction={{ sm: "column", lg: "row" }}
            mb={{ sm: "42px", lg: "84px" }}
          >
            <Flex
              direction="column"
              me={{ lg: "70px", xl: "120px" }}
              mb={{ sm: "24px", lg: "0px" }}
            >
              <Box
                w={{ sm: "275px", md: "670px", lg: "450px", xl: "600px" }}
                h={{ sm: "200px", md: "500px", lg: "330px", xl: "500px" }}
                mb="26px"
                mx={{ sm: "auto", lg: "0px" }}
              >
                <Image
                  src={currentImage}
                  w="100%"
                  h="100%"
                  borderRadius="15px"
                />
              </Box>
              <Stack
                direction="row"
                spacing={{ sm: "20px", md: "35px", lg: "20px" }}
                mx="auto"
                mb={{ sm: "24px", lg: "0px" }}
              >
                <Box
                  w={{ sm: "36px", md: "90px", lg: "60px" }}
                  h={{ sm: "36px", md: "90px", lg: "60px" }}
                >
                  <Image
                    src={tncSd236afsImg}
                    w="100%"
                    h="100%"
                    borderRadius="15px"
                    cursor="pointer"
                    onClick={(e) => setCurrentImage(e.target.src)}
                  />
                </Box>
              </Stack>
            </Flex>
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="3xl"
                fontWeight="bold"
                mb="12px"
              >
                TNC-SD818AFS
              </Text>
              <Flex direction="row">
                <Badge
                    colorScheme="green"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  Network
                </Badge>
                &nbsp;
                <Badge
                    colorScheme="red"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  IR
                </Badge>
                &nbsp;
                <Badge
                    colorScheme="blue"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  8M
                </Badge>
              </Flex>

              <Text color="gray.400" fonSize="sm" fontWeight="normal" mb="8px">
                Key Features
              </Text>
              <UnorderedList spacing="8px" mb="40px">
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  고화질 Full HD IR 네트워크 PTZ 카메라 (3840x2160@60fps)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  1/1.8” Sony Exmor CMOS sensor_IMX678, W / AMBA SOC
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  18배 광학, 8배 디지털 줌 렌즈 탑재
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  WDR(Wide Dynamic Range) 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  DNR(2D+3D) 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  H.265 / H.264 / MJPEG 트리플 스트리밍 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  WIPER 기본 장착으로 손쉬운 먼지 및 물기 제거
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  햇볕이나 비, 눈을 가려주는 하우징 구조(porch)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  Day & Night 또는 CDS를 이용한 IR 동작 제어
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  512 프리셋 위치 지정
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  ROI Encoding 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  카메라 세부 스케쥴 기능으로 주, 야간 최적의 영상 제공
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  4EA 레이져LED, 6EA IR LED 적용 IR 최대 거리 300M
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  자동 위치 보정기능으로 물리적, 기구적 이동으로 인한 오차 보정
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  양방향 오디오 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  IP 66
                </ListItem>
              </UnorderedList>
              <Flex direction="row">

                <Button
                    variant="no-hover"
                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                    w={{sm: "240px", md: "100%", lg: "240px"}}
                    h="50px"
                    mx={{sm: "auto", md: "0px"}}
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                >
                  <a href={"/#/support"}>
                    Download Manual
                  </a>
                </Button>
                &nbsp;
                <Button
                    variant="no-hover"
                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                    w={{sm: "240px", md: "100%", lg: "240px"}}
                    h="50px"
                    mx={{sm: "auto", md: "0px"}}
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                >
                  <a href="/software/Setup_IPManager_v2.1.0.8.exe.xz" download>
                    Download Ip Manager
                  </a>
                </Button>
              </Flex>
            </Flex>
          </Flex>

          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Product Spec
          </Text>

          <Box w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Table variant="simple" w="100%">
              <Thead>
                <Tr>
                  <Th color="gray.400" fontSize="xs">
                    Feature
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                    TNC-SD818AFS
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">총화소수</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">3856(H) x 2200(V)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">유효화소</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">3840(H) x 2160(V) 8M Pixels</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">촬상소자</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1/1.8” Sony Exmor CMOS Sensor</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Scanning system</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Progressive Scan</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">S/N비</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">56dB 이상 (AGC off)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최저조도</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">0lux(IR ON)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">렌즈 줌 배율</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Optical x18, Digital x10 Zoom</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">신호 출력</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">CVBS (Option)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Focal Length</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">f=6.8mm ~ 120.0mm</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Aperture Ratio</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">F1.5(wide) ~ F4.8(tele)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Main Stream</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">H.265/H.264/MJPEG</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Other Stream</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">H.264/MPEG-4/MJPEG</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Resolutions</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1080P ~ QCIF</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">오디오 입력</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1Line-In (G.711 / G.726)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">오디오 출력</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1Line-Out (G.711 / G.726)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 설정 수</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">512개</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 속도 가변 지정</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">저속, 중속, 고속 (360°/Sec)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 이름 개별 설정 기능</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">On/Off</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 Pan/Tilt속도</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Pan 0~360°, Tilt 0~90°/Sec 가변 설정 가능</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">회전 동작 범위</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">0°~360° 연속 회전(Pan) / 0°~180°(Tilt, ±90°)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">최대 회전 속도</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Pan 360° / Sec</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">IR 최대 거리</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">300M</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">IR 방사각도</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">WIDE 60도, TELE 15도</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">WIPER</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">안개 보정기능</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">On/Off</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">음직임 기능</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">패턴 기능</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">5 Pattern(360sec)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">스윙, Preset 위치 설정</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">센서 입/출력</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">2 Input 2Relay Output</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">정전 복구 기능</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">마지막 위치 기억</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Tour 기능</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">8 Group</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">FAN, HEATER</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">FAN 1개, HEATER 1개 내장</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">인증</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">KC, IP66, IK10</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">시간 스케쥴링 기능</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">특정 영역 차단 기능(사생활 보호)</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">8개 영역 (On/Off), 24positions</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Compression</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">H.265/H.264/MPEG-4/MJPEG Triple Streaming & Jpeg</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Resolution</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">1080P, 720P, 1024x576, 640x360, D1, 4CIF, VGA, CIF, QVGA, QCIF</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Bit Rate</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">32Kbps ~ 10Mbps</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Frame Rate</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">60fps up to 3840x2160</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Ethernet</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Ethernet 10/100 Base-T(RJ-45)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Audio</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Two way full duplex G.711 / G.726</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Protocol</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">HTTP, RTP/RTSP, TCP/IP, UDP, FTP, Telnet, DHCP, PPPoE, DNS, NTP, ICMP, SNMP, UPnP, Bonjure, ZeroConf, Onvif, Genetec</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">ROI Encoding</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Home Position 기능</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">자체 진단 기능</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Yes</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">일반</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">동작 온도/습도: -40°C ~ 70°C / 0 ~ 90RH % 이하</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">재질</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Aluminum , PLASTIC</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">사용 전원</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">DC12V 5A 60Hz / 30W, PoE(전용 인젝터 지원)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">중량</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">4.3Kg (±2%)</Text></Td>
                </Tr>
                <Tr>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">외형 치수</Text></Td>
                  <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">193 (Ø) x 350(H) mm</Text></Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>

          <br/>

          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Other Products
          </Text>

          <Box w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Table variant="simple" w="100%">
              <Thead>
                <Tr>
                  <Th color="gray.400" fontSize="xs">
                    Model Name
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncSd236afsImg}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/speed-dome/tnc-sd236afs">
                        <Text color={textColor} fontSize="sm" fontWeight="bold">
                          TNC-SD236AFS
                        </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      HD
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      IR
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
}

export default TncSd818afs;
