import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  ListItem,
  Progress,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import tncSd236afsImg from "./img/TNC-SD236AFS.png";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { BsStarFill, BsStarHalf } from "react-icons/bs";

function TncSd236afs() {
  const [currentImage, setCurrentImage] = useState(tncSd236afsImg);
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card mt={{ sm: "125px", md: "75px" }}>
      <CardHeader mb="42px">
        <Text color={textColor} fontSize="lg" fontWeight="bold">
          Product Details
        </Text>
      </CardHeader>
      <CardBody>
        <Flex direction="column" w="100%">
          <Flex
            direction={{ sm: "column", lg: "row" }}
            mb={{ sm: "42px", lg: "84px" }}
          >
            <Flex
              direction="column"
              me={{ lg: "70px", xl: "120px" }}
              mb={{ sm: "24px", lg: "0px" }}
            >
              <Box
                w={{ sm: "275px", md: "670px", lg: "450px", xl: "600px" }}
                h={{ sm: "200px", md: "500px", lg: "330px", xl: "500px" }}
                mb="26px"
                mx={{ sm: "auto", lg: "0px" }}
              >
                <Image
                  src={currentImage}
                  w="100%"
                  h="100%"
                  borderRadius="15px"
                />
              </Box>
              <Stack
                direction="row"
                spacing={{ sm: "20px", md: "35px", lg: "20px" }}
                mx="auto"
                mb={{ sm: "24px", lg: "0px" }}
              >
                <Box
                  w={{ sm: "36px", md: "90px", lg: "60px" }}
                  h={{ sm: "36px", md: "90px", lg: "60px" }}
                >
                  <Image
                    src={tncSd236afsImg}
                    w="100%"
                    h="100%"
                    borderRadius="15px"
                    cursor="pointer"
                    onClick={(e) => setCurrentImage(e.target.src)}
                  />
                </Box>
              </Stack>
            </Flex>
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="3xl"
                fontWeight="bold"
                mb="12px"
              >
                TNC-SD236AFS
              </Text>
              <Flex direction="row">
                <Badge
                    colorScheme="green"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  Network
                </Badge>
                &nbsp;
                <Badge
                    colorScheme="red"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  IR
                </Badge>
                &nbsp;
                <Badge
                    colorScheme="blue"
                    w="95px"
                    h="28px"
                    mb="40px"
                    borderRadius="15px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  HD
                </Badge>
              </Flex>

              <Text color="gray.400" fonSize="sm" fontWeight="normal" mb="8px">
                Key Features
              </Text>
              <UnorderedList spacing="8px" mb="40px">
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  고화질 Full HD IR 네트워크 PTZ 카메라 (1920x1080@60fps)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  1/2” Sony Exmor CMOS sensor_IMX385, W / AMBA SOC
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  36배 광학, 8배 디지털 줌 렌즈 탑재
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  WDR(Wide Dynamic Range) 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  DNR(2D+3D) 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  H.265 / H.264 / MJPEG 트리플 스트리밍 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  WIPER 기본 장착으로 손쉬운 먼지 및 물기 제거
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  햇볕이나 비, 눈을 가려주는 하우징 구조(porch)
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  Day & Night 또는 CDS를 이용한 IR 동작 제어
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  512 프리셋 위치 지정
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  ROI Encoding 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  카메라 세부 스케쥴 기능으로 주, 야간 최적의 영상 제공
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  4EA 레이저 LED, 6EA IR LED 적용 IR 최대 거리 300M
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  자동 위치 보정 기능으로 물리적, 기구적 이동으로 인한 오차 보정
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  양방향 오디오 지원
                </ListItem>
                <ListItem fontSize="md" color={textColor} fontWeight="normal">
                  IP 66
                </ListItem>
              </UnorderedList>
              <Flex direction="row">

                <Button
                    variant="no-hover"
                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                    w={{sm: "240px", md: "100%", lg: "240px"}}
                    h="50px"
                    mx={{sm: "auto", md: "0px"}}
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                >
                  <a href={"/#/support"}>
                    Download Manual
                  </a>
                </Button>
                &nbsp;
                <Button
                    variant="no-hover"
                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                    w={{sm: "240px", md: "100%", lg: "240px"}}
                    h="50px"
                    mx={{sm: "auto", md: "0px"}}
                    color="#fff"
                    fontSize="xs"
                    fontWeight="bold"
                >
                  <a href="/software/Setup_IPManager_v2.1.0.8.exe.xz" download>
                    Download Ip Manager
                  </a>
                </Button>
              </Flex>
            </Flex>
          </Flex>

          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Product Spec
          </Text>

          <Box w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Table variant="simple" w="100%">
              <Thead>
                <Tr>
                  <Th color="gray.400" fontSize="xs">
                    Feature
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                    TNC-SD236AFS
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">총화소수</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">1952(H) x 1113(V) 2.17M Pixels</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">유효화소</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">1945(H) x 1097(V) 2.13M Pixels</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">촬상소자</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">1/2” Sony Exmor CMOS Sensor</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">Scanning system</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">Progressive Scan</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">S/N비</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">56dB 이상 (AGC off)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">최저조도</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">0lux(IR ON)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">렌즈 줌 배율</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">Optical x36, Digital x32 Zoom</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">신호 출력</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">CVBS (Option)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">Focal Length</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">f=6.0mm ~ 216.0mm</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">Aperture Ratio</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">F1.5(wide) ~ F4.8(tele)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">Main Stream</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">H.265/H.264/MJPEG</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">Other Stream</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">H.264/MPEG-4/MJPEG</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">Resolutions</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">1080P ~ QCIF</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">오디오 입력</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">1Line-In (G.711 / G.726)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">오디오 출력</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">1Line-Out (G.711 / G.726)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 설정 수</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">512개</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 속도 가변 지정</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">저속, 중속, 고속 (360°/Sec)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 이름 개별 설정 기능</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">On/Off</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">프리셋 Pan/Tilt 속도</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">Pan 0~360°, Tilt 0~90°/Sec가변설정가능</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">회전 동작 범위</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">0°~360° 연속회전(Pan) / 0°~180°(Tilt, ±90°)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">최대 회전 속도</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">Pan 360° / Sec</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">IR 최대 거리</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">300M</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">IR 방사각도</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">WIDE 60도, TELE 15도</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">전원 공급</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">DC 12V ± 10%, PoE(IEEE 802.3at)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">소비전력</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">17W(최대 2개 렌즈 구동시)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">작동 온도</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">-20℃ ~ 60℃</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">습도</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">10% ~ 90% (비응결)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">크기</Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">∅246 x 426 mm</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">무게</Text>
                  </Td>
                  <Td>ll
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">2.6kg</Text>
                  </Td>
                </Tr>
                <Tr>
                <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">일반</Text></Td>
                <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">동작 온도/습도: -40°C ~ 70°C / 0 ~ 90RH % 이하</Text></Td>
              </Tr>
              <Tr>
                <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">재질</Text></Td>
                <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">Aluminum , PLASTIC</Text></Td>
              </Tr>
              <Tr>
                <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">사용 전원</Text></Td>
                <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">DC12V 5A 60Hz / 30W, PoE(전용 인젝터 지원)</Text></Td>
              </Tr>
              <Tr>
                <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">중량</Text></Td>
                <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">4.3Kg (±2%)</Text></Td>
              </Tr>
              <Tr>
                <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">외형 치수</Text></Td>
                <Td><Text color="gray.500" fontSize="sm" fontWeight="bold">193 (Ø) x 350(H) mm</Text></Td>
              </Tr>
              </Tbody>
            </Table>
          </Box>

          <br/>

          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Other Products
          </Text>

          <Box w="100%" overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Table variant="simple" w="100%">
              <Thead>
                <Tr>
                  <Th color="gray.400" fontSize="xs">
                    Model Name
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                  <Th color="gray.400" fontSize="xs">
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td minW="300px">
                    <Flex align="center">
                      <Box w="40px" h="40px" me="14px">
                        <Image
                            src={tncSd236afsImg}
                            w="100%"
                            h="100%"
                            borderRadius="12px"
                        />
                      </Box>
                      <a href="/#/product/speed-dome/tnc-sd818afs">
                        <Text color={textColor} fontSize="sm" fontWeight="bold">
                          TNC-SD818AFS
                        </Text>
                      </a>
                    </Flex>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      Network
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      4K
                    </Text>
                  </Td>
                  <Td>
                    <Text color="gray.500" fontSize="sm" fontWeight="bold">
                      8M
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
}

export default TncSd236afs;
