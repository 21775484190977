// Chakra imports
import {
    Button,
    Flex,
    Grid, Text, useColorModeValue
} from "@chakra-ui/react";
// Custom icons
import React from "react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";

export default function Default() {
  // Chakra Color Mode
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text
        color={textColor}
        fontWeight="bold"
        fontSize="3xl"
        mb="30px"
        ps="20px"
      >
        Support
      </Text>

      <Grid
        gap="24px"
        mb={{ lg: "26px" }}
      >
          <Card p="16px">
              <CardBody>
                  <Flex direction="column" w="100%">
                      <Flex direction="column" mt="24px" mb="36px" alignSelf="flex-start">
                          <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
                              Catalog
                          </Text>
                          <Text fontSize="md" fontWeight="medium" color="gray.400">
                              <Text
                                  as="span"
                                  color={"green.400"}
                                  fontWeight="bold"
                              >
                              </Text>{" "}
                                You can download catalog for our products.
                          </Text>
                          <br/>
                          <Grid
                              templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr", xl: "repeat(4, 1fr)" }}
                              gap="24px"
                              mb={{ lg: "26px" }}
                          >
                              <Card p='28px 10px 0px 0px' mb={{ sm: "28px", lg: "0px" }}>
                                  <Button
                                      variant="no-hover"
                                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                      w={{ sm: "100%", md: "100%", lg: "300px" }}
                                      h="50px"
                                      mx={{ sm: "auto", md: "0px" }}
                                      color="#fff"
                                      fontSize="xs"
                                      fontWeight="bold"
                                  >
                                      <a href="/catalog/tgcam_bullet series product_rev1.0.pdf" download>
                                          BULLET SERIES PRODUCT
                                      </a>
                                  </Button>
                              </Card>
                              <Card p='28px 10px 0px 0px' mb={{ sm: "28px", lg: "0px" }}>
                                  <Button
                                      variant="no-hover"
                                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                      w={{ sm: "100%", md: "100%", lg: "300px" }}
                                      h="50px"
                                      mx={{ sm: "auto", md: "0px" }}
                                      color="#fff"
                                      fontSize="xs"
                                      fontWeight="bold"
                                  >
                                      <a href="/catalog/tgcam speed dome series product rev1.0.pdf" download>
                                          DOME SERIES PRODUCT
                                      </a>
                                  </Button>
                              </Card>
                              <Card p='28px 10px 0px 0px' mb={{ sm: "28px", lg: "0px" }}>
                                  <Button
                                      variant="no-hover"
                                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                      w={{ sm: "100%", md: "100%", lg: "300px" }}
                                      h="50px"
                                      mx={{ sm: "auto", md: "0px" }}
                                      color="#fff"
                                      fontSize="xs"
                                      fontWeight="bold"
                                  >
                                      <a href="/catalog/tgcam_dome series product_rev1.0.pdf" download>
                                          SPEED DOME SERIES PRODUCT
                                      </a>
                                  </Button>
                              </Card>
                              <Card p='28px 10px 0px 0px' mb={{ sm: "28px", lg: "0px" }}>
                                  <Button
                                      variant="no-hover"
                                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                      w={{ sm: "100%", md: "100%", lg: "300px" }}
                                      h="50px"
                                      mx={{ sm: "auto", md: "0px" }}
                                      color="#fff"
                                      fontSize="xs"
                                      fontWeight="bold"
                                  >
                                      <a href="/catalog/tgcam_ptz series product_rev3.0.pdf" download>
                                          PTZ SERIES PRODUCT
                                      </a>
                                  </Button>
                              </Card>
                              <Card p='28px 10px 0px 0px' mb={{ sm: "28px", lg: "0px" }}>
                                  <Button
                                      variant="no-hover"
                                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                      w={{ sm: "100%", md: "100%", lg: "300px" }}
                                      h="50px"
                                      mx={{ sm: "auto", md: "0px" }}
                                      color="#fff"
                                      fontSize="xs"
                                      fontWeight="bold"
                                  >
                                      <a href="/catalog/tgcam_housing series product_rev1.0.pdf" download>
                                          HOUSING PRODUCT
                                      </a>
                                  </Button>
                              </Card>
                              <Card p='28px 10px 0px 0px' mb={{ sm: "28px", lg: "0px" }}>
                                  <Button
                                      variant="no-hover"
                                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                      w={{ sm: "100%", md: "100%", lg: "300px" }}
                                      h="50px"
                                      mx={{ sm: "auto", md: "0px" }}
                                      color="#fff"
                                      fontSize="xs"
                                      fontWeight="bold"
                                  >
                                      <a href="/catalog/tgcam_ai_dual_light_series product_rev5.0.pdf" download>
                                          AI DUAL LIGHT PRODUCT
                                      </a>
                                  </Button>
                              </Card>
                          </Grid>

                      </Flex>
                  </Flex>

              </CardBody>
          </Card>

          <Card p="16px">
              <CardBody>
                  <Flex direction="column" w="100%">

                      <Flex direction="column" mt="24px" mb="36px" alignSelf="flex-start">
                          <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
                                Software
                          </Text>
                          <Text fontSize="md" fontWeight="medium" color="gray.400">
                              <Text
                                  as="span"
                                  color={"green.400"}
                                  fontWeight="bold"
                              >
                              </Text>{" "}
                              You can download software for our products.
                          </Text>
                          <br/>

                          <Grid
                              templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr", xl: "repeat(4, 1fr)" }}
                              gap="24px"
                              mb={{ lg: "26px" }}
                          >
                              <Card p='28px 10px 0px 0px' mb={{ sm: "26px", lg: "0px" }}>
                                  <Button
                                      variant="no-hover"
                                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                      w={{sm: "100%", md: "100%", lg: "300px"}}
                                      h="50px"
                                      mx={{sm: "auto", md: "0px"}}
                                      color="#fff"
                                      fontSize="xs"
                                      fontWeight="bold"
                                  >
                                      <a href="/software/Setup_IPManager_v2.1.0.8.exe.xz" download>
                                          IP Manager(ver 2.1.0.8)
                                      </a>
                                  </Button>
                              </Card>

                          </Grid>
                      </Flex>
                  </Flex>

              </CardBody>
          </Card>

          <Card p="16px">
              <CardBody>
                  <Flex direction="column" w="100%">

                      <Flex direction="column" mt="24px" mb="36px" alignSelf="flex-start">
                          <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
                                Manual
                          </Text>
                          <Text fontSize="md" fontWeight="medium" color="gray.400">
                              <Text
                                  as="span"
                                  color={"green.400"}
                                  fontWeight="bold"
                              >
                              </Text>{" "}
                                You can download manual for our products.
                          </Text>
                            <br/>

                          <Grid
                              templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr", xl: "repeat(4, 1fr)" }}
                              gap="24px"
                              mb={{ lg: "26px" }}
                          >
                              <Card p='28px 10px 0px 0px' mb={{ sm: "26px", lg: "0px" }}>
                                  <Button
                                      variant="no-hover"
                                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                      w={{sm: "100%", md: "100%", lg: "300px"}}
                                      h="50px"
                                      mx={{sm: "auto", md: "0px"}}
                                      color="#fff"
                                      fontSize="xs"
                                      fontWeight="bold"
                                  >
                                      <a href="/manual/Mini PTZ _ Web-based Korea Instructions - TGCAM _20240207.pdf"
                                         download>
                                          Mini PTZ Web-based Korea Instructions
                                      </a>
                                  </Button>
                              </Card>
                              <Card p='28px 10px 0px 0px' mb={{sm: "26px", lg: "0px"}}>
                              <Button
                                      variant="no-hover"
                                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                      w={{ sm: "100%", md: "100%", lg: "300px" }}
                                      h="50px"
                                      mx={{ sm: "auto", md: "0px" }}
                                      color="#fff"
                                      fontSize="xs"
                                      fontWeight="bold"
                                  >
                                      <a href="/manual/PTZ BULLET _ Web-based Korea Instructions - TGCAM _20230323.pdf" download>
                                          PTZ BULLET Web-based Korea Instructions
                                      </a>
                                  </Button>
                              </Card>

                          </Grid>

                      </Flex>
                  </Flex>


              </CardBody>
          </Card>
      </Grid>

    </Flex>
  );
}
