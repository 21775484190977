import {
  DocumentIcon,
} from "components/Icons/Icons";
import Main from "views/Home/Main";
import AllProductsIndex from "views/Home/AllProducts"
import TncBz236v12af from "./views/Products/Bullet/tncBz236v12af";
import TncB210v12afs from "./views/Products/Bullet/tncB210v12afs";
import TncB510v12af from "./views/Products/Bullet/tncB510v12af";
import TncB120v12afs from "./views/Products/Bullet/tncB120v12afs";
import TncBz204v12afs from "views/Products/Bullet/tncBz204v12afs";
import Utc8060r from "views/Products/Bullet/utc8060r";

import TncFv120f from "views/Products/Dome/tncFv120F";
import TncV210f18 from "views/Products/Dome/tncV210f18";
import TncD210v21af from "views/Products/Dome/tncD210v21af";
import TncD210v21afs from "views/Products/Dome/tncD210v21afs";
import TncD210v30afs from "views/Products/Dome/tncD210v30afs";
import TncD510v21af from "./views/Products/Dome/tncD510v21af";
import Utc800dr from "views/Products/Dome/utc800dr";
import ItvsSpt2m36af from "./views/Products/Ptz/itvsSpt2m36af";

import tncSd236afs from "./views/Products/SpeedDome/tncSd236afs";
import tncSd818afs from "./views/Products/SpeedDome/tncSd818afs";

import {ChevronRightIcon} from "@chakra-ui/icons";
import TncBp516afs from "./views/Products/Ptz/tncBp516afs";
import TncBp21v08afs from "./views/Products/Ptz/tncBp21v08afs";
import TncVs210af from "./views/Products/Ptz/tncVs210af";
import TncVs210afs from "./views/Products/Ptz/tncVs210afs";
import TncVs510afs from "./views/Products/Ptz/tncVs510afs";

import TncGb210v12afs from "./views/Products/Special/tncGb210v12afs";
import TncV210v06af from "./views/Products/Special/tncV210v06af";
import TncV210v06afs from "./views/Products/Special/tncV210v06afs";
import TncV510v06afs from "./views/Products/Special/tncV510v06afs";
import Contact from "./views/Contact";
import Support from "./views/Support";
import PatentsCertifications from "./views/Certifications";
import TncSd236afs from "./views/Products/SpeedDome/tncSd236afs";
import TncSd818afs from "./views/Products/SpeedDome/tncSd818afs";


const dashRoutes = [
  {
    name: "ABOUT US",
    category: "aboutus",
    items: [
      {
        name: "HOME",
        path: "/home",
        icon: <ChevronRightIcon color="inherit" />,
        component: Main,
        layout: "",
      },
      {
        name: "ALL PRODUCTS",
        path: "/allproducts",
        icon: <ChevronRightIcon color="inherit" />,
        component: AllProductsIndex,
        layout: "",
      },
      {
        name: "PATENTS & CERTIFICATIONS",
        path: "/certifications",
        icon: <ChevronRightIcon color="inherit" />,
        component: PatentsCertifications,
        layout: "",
      },
      {
        name: "CONTACT US",
        path: "/contactus",
        icon: <ChevronRightIcon color="inherit" />,
        component: Contact,
        layout: "",
      },
      {
        name: "SUPPORT",
        path: "/support",
        icon: <ChevronRightIcon color="inherit" />,
        component: Support,
        layout: "",
      },
    ]
  },
  {
    name: "PRODUCTS",
    category: "product",
    items: [
      {
        name: "BULLET",
        path: "/product/bullet",
        collapse: true,
        icon: <DocumentIcon color="inherit" />,
        items: [
          {
            name: "TNC-BZ236V12AF",
            component: TncBz236v12af,
            path: "/product/bullet/tnc-bz236v12af",
            layout: "",
          },
          {
            name: "TNC-BZ204V12AFS",
            component: TncBz204v12afs,
            path: "/product/bullet/tnc-bz204v12afs",
            layout: "",
          },
          {
            name: "TNC-B210V12AFS",
            component: TncB210v12afs,
            path: "/product/bullet/tnc-b210v12afs",
            layout: "",
          },
          {
            name: "TNC-B510V12AF",
            component: TncB510v12af,
            path: "/product/bullet/tnc-b510v12af",
            layout: "",
          },
          {
            name: "TNC-B120V12AFS",
            component: TncB120v12afs,
            path: "/product/bullet/tnc-b120v12afs",
            layout: "",
          },
          {
            name: "UTC-8060R",
            component: Utc8060r,
            path: "/product/bullet/utc-8060r",
            layout: "",
          },
        ]
      },
      {
        name: "DOME",
        path: "/product/dome",
        collapse: true,
        icon: <DocumentIcon color="inherit" />,
        items: [
          {
            name: "TNC-FV120F",
            component: TncFv120f,
            path: "/product/dome/tnc-fv120f",
            layout: "",
          },
          {
            name: "TNC-V210F18",
            component: TncV210f18,
            path: "/product/dome/tnc-v210f18",
            layout: "",
          },
          {
            name: "TNC-D210V21AF",
            component: TncD210v21af,
            path: "/product/dome/tnc-d210v21af",
            layout: "",
          },
          {
            name: "TNC-D210V21AFS",
            component: TncD210v21afs,
            path: "/product/dome/tnc-d210v21afs",
            layout: "",
          },
          {
            name: "TNC-D210V30AFS",
            component: TncD210v30afs,
            path: "/product/dome/tnc-d210v30afs",
            layout: "",
          },
          {
            name: "TNC-D510V21AF",
            component: TncD510v21af,
            path: "/product/dome/tnc-d510v21af",
            layout: "",
          },
          {
            name: "UTC-800DR",
            component: Utc800dr,
            path: "/product/dome/utc-800dr",
            layout: "",
          },
        ]
      },
      {
        name: "SPEED DOME",
        path: "/product/speed-dome",
        collapse: true,
        icon: <DocumentIcon color="inherit" />,
        items: [
          {
            name: "TNC-SD236AFS",
            component: TncSd236afs,
            path: "/product/speed-dome/tnc-sd236afs",
            layout: "",
          },
          {
            name: "TNC-SD818AFS",
            component: TncSd818afs,
            path: "/product/speed-dome/tnc-sd818afs",
            layout: "",
          },
        ]
      },
      {
        name: "PTZ",
        path: "/product/ptz",
        collapse: true,
        icon: <DocumentIcon color="inherit" />,
        items: [
          {
            name: "TNC-BP516AFS",
            component: TncBp516afs,
            path: "/product/ptz/tnc-bp516afs",
            layout: "",
          },
          {
            name: "TNC-BP21V36AFS",
            component: ItvsSpt2m36af,
            path: "/product/ptz/tnc-bp21v36afs",
            layout: "",
          },
          {
            name: "TNC-BP21V08AFS",
            component: TncBp21v08afs,
            path: "/product/ptz/tnc-bp21v08afs",
            layout: "",
          },
          {
            name: "TNC-VS210AF",
            component: TncVs210af,
            path: "/product/ptz/tnc-vs210af",
            layout: "",
          },
          {
            name: "TNC-VS210AFS",
            component: TncVs210afs,
            path: "/product/ptz/tnc-vs210afs",
            layout: "",
          },
          {
            name: "TNC-VS510AFS",
            component: TncVs510afs,
            path: "/product/ptz/tnc-vs510afs",
            layout: "",
          },
        ]
      },
      {
        name: "SPECIAL",
        path: "/product/special",
        collapse: true,
        icon: <DocumentIcon color="inherit" />,
        items: [
          {
            name: "TNC-GB210V12AFS",
            component: TncGb210v12afs,
            path: "/product/special/tnc-gb210v12afs",
            layout: "",
          },
          {
            name: "TNC-V210V06AF",
            component: TncV210v06af,
            path: "/product/special/tnc-v210v06af",
            layout: "",
          },
          {
            name: "TNC-V210V06AFS",
            component: TncV210v06afs,
            path: "/product/special/tnc-v210v06afs",
            layout: "",
          },
          {
            name: "TNC-V510V06AFS",
            component: TncV510v06afs,
            path: "/product/special/tnc-v510v06afs",
            layout: "",
          },
        ]
      },
    ]
  }
];

export default dashRoutes;
