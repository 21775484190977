// Chakra imports
import {
    Box, Button,
    Flex,
    Grid, Link, SimpleGrid, Stack, Text, useColorModeValue
} from "@chakra-ui/react";
import BarChart from "../../components/Charts/BarChart";
import LineChart from "../../components/Charts/LineChart";
// Custom icons
import {
    CartIcon,
    DocumentIcon,
    GlobeIcon, WalletIcon
} from "../../components/Icons/Icons.js";
import React from "react";
import {
    barChartDataDefault,
    barChartOptionsDefault,
    lineChartDataDefault,
    lineChartOptionsDefault
} from "../../variables/charts";
import { salesData } from "../../variables/general";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";

export default function Default() {
  // Chakra Color Mode
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text
        color={textColor}
        fontWeight="bold"
        fontSize="3xl"
        mb="30px"
        ps="20px"
      >
        Support
      </Text>

      <Grid
        gap="24px"
        mb={{ lg: "26px" }}
      >

          <Card p="16px">
              <CardBody>
                  <Flex direction="column" w="100%">
                      <Flex direction="column" mt="24px" mb="36px" alignSelf="flex-start">
                          <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
                              특허 (Patent)
                          </Text>
                          <Text fontSize="md" fontWeight="medium" color="gray.400">
                              <Text
                                  as="span"
                                  color={"green.400"}
                                  fontWeight="bold"
                              >
                              </Text>{" "}
                          </Text>
                          <br/>
                          <Grid
                              templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr", xl: "repeat(4, 1fr)" }}
                              gap="24px"
                              mb={{ lg: "26px" }}
                          >
                              <Card p='28px 10px 0px 0px' mb={{ sm: "28px", lg: "0px" }}>
                                  <object className="pdf"
                                          data="/patentsCertifications/tgcam_patent_1.pdf"
                                          width="600"
                                          height="900">
                                  </object>
                              </Card>
                          </Grid>

                      </Flex>
                  </Flex>

              </CardBody>
          </Card>

          <Card p="16px">
              <CardBody>
                  <Flex direction="column" w="100%">

                      <Flex direction="column" mt="24px" mb="36px" alignSelf="flex-start">
                          <Text fontSize="lg" color={textColor} fontWeight="bold" mb="6px">
                                Certification
                          </Text>
                          <Text fontSize="md" fontWeight="medium" color="gray.400">
                              <Text
                                  as="span"
                                  color={"green.400"}
                                  fontWeight="bold"
                              >
                              </Text>{" "}
                          </Text>
                          <br/>

                          <Grid
                              templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr", xl: "repeat(4, 1fr)" }}
                              gap="24px"
                              mb={{ lg: "26px" }}
                          >
                              <Card p='28px 10px 0px 0px' mb={{ sm: "26px", lg: "0px" }}>
                                  <Card p='28px 10px 0px 0px' mb={{ sm: "28px", lg: "0px" }}>
                                      <object className="pdf"
                                              data="/patentsCertifications/MINI_PTZ_KC.pdf"
                                              width="600"
                                              height="900">
                                      </object>
                                  </Card>
                                  <Card p='28px 10px 0px 0px' mb={{ sm: "28px", lg: "0px" }}>
                                      <object className="pdf"
                                              data="/patentsCertifications/PTZ_CAMERA_TNC-BP21V36AFS_KC.pdf"
                                              width="600"
                                              height="900">
                                      </object>
                                  </Card>
                                  <Card p='28px 10px 0px 0px' mb={{ sm: "28px", lg: "0px" }}>
                                      <object className="pdf"
                                              data="/patentsCertifications/TNC_G200CS.pdf"
                                              width="600"
                                              height="900">
                                      </object>
                                  </Card>
                              </Card>

                          </Grid>
                      </Flex>
                  </Flex>

              </CardBody>
          </Card>

      </Grid>

    </Flex>
  );
}
